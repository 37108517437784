import React, { useState } from "react";
import PropTypes from "prop-types";
import useWindowSize from "@/hooks/useWindowSize";
import Link from "next/link";
import { ArrowNarrowRightIcon } from "@heroicons/react/outline";
import * as Text from "@/components/text";
import SvgLogo from "@/components/lib/SvgLogo";
import breakpoints from "@/constants/breakpoinst";
import classNames from "classnames";
import Button from "../actions/Button";
import { motion } from "framer-motion";
import { useGetFilmsByInitiatives } from "@/actions/useMovies";
import Image from "next/legacy/image";

const initiatives = {
  "gira-ambulante": {
    accent: "text-gira group-hover:text-neutral-900",
    "positive-text": "text-neutral-300",
    "negative-text": "hover:text-neutral-900 hover:bg-gira",
    "link-positive": "dark",
    "link-negative": "light",
    hr: "border-gira group-hover:border-neutral-900",
    title: "Gira",
    secondTitle: "Gira de documentales",
    description: "Conoce más de nuestra gira de cine itinerante.",
    link: "/iniciativa/gira/",
  },
  "ambulante-presenta": {
    accent: "text-presenta group-hover:text-neutral-900",
    "positive-text": "text-neutral-300",
    "negative-text": "hover:text-neutral-900 hover:bg-presenta",
    "link-positive": "dark",
    "link-negative": "light",
    hr: "border-presenta group-hover:border-neutral-900",
    title: "Presenta",
    secondTitle: "Presenta",
    description: "Exhibición gratuita de películas todo el año.",
    link: "/ambulante-presenta",
  },
  "ambulante-mas-alla": {
    accent: "text-mas-alla group-hover:text-neutral-900",
    "positive-text": "text-neutral-300",
    "negative-text": "hover:text-neutral-900 hover:bg-mas-alla",
    "link-positive": "dark",
    "link-negative": "light",
    hr: "border-mas-alla group-hover:border-neutral-900",
    title: "Más Allá",
    secondTitle: "Más Allá",
    description: "Proyecto de capacitación en producción documental.",
    link: "/ambulante-mas-alla",
  },
  ediciones: {
    accent: "text-ediciones group-hover:text-neutral-900",
    "positive-text": "text-neutral-300",
    "negative-text": "hover:text-neutral-900 hover:bg-ediciones",
    "link-positive": "dark",
    "link-negative": "light",
    hr: "border-ediciones group-hover:border-neutral-900",
    title: "Ediciones",
    description: "Obras fundamentales para expandir la cultura del cine.",
    link: "/ambulante-ediciones",
  },
  incidencias: {
    accent: "text-incidencias group-hover:text-neutral-900",
    "positive-text": "text-neutral-300",
    "negative-text": "hover:text-neutral-900 hover:bg-incidencias",
    "link-positive": "dark",
    "link-negative": "light",
    hr: "border-incidencias group-hover:border-neutral-900",
    title: "Incidencias",
    description: "Campañas sobre causas que merecen ser acompañadas.",
    link: "/incidencias",
  },
  distribucion: {
    accent: "text-distribucion group-hover:text-neutral-900",
    "positive-text": "text-neutral-300",
    "negative-text": "hover:text-neutral-900 hover:bg-distribucion",
    "link-positive": "dark",
    "link-negative": "light",
    hr: "border-distribucion group-hover:border-neutral-900",
    title: "Distribución",
    description: "Obras fundamentales para expandir la cultura del cine.",
    link: "/distribucion",
  },
  becasyfondos: {
    accent: "text-becasyfondos group-hover:text-neutral-900",
    "positive-text": "text-neutral-300",
    "negative-text": "hover:text-neutral-900 hover:bg-becasyfondos",
    "link-positive": "dark",
    "link-negative": "light",
    hr: "border-becasyfondos group-hover:border-neutral-900",
    title: "Becas y Fondos",
    description: "Obras fundamentales para expandir la cultura del cine.",
    link: "/becas-y-fondos",
  },
  proyectosEspeciales: {
    accent: "text-pryectos-especiales group-hover:text-neutral-900",
    "positive-text": "text-neutral-300",
    "negative-text": "hover:text-neutral-900 hover:bg-proyectos-especiales",
    "link-positive": "dark",
    "link-negative": "light",
    hr: "border-proyectos-especiales group-hover:border-neutral-900",
    title: "Proyectos Especiales",
    description: "Obras fundamentales para expandir la cultura del cine.",
    link: "/proyectos-especiales",
  },
};

export default function InitiativeCard({ h = "", inititative, ...rest }) {
  const windowSize = useWindowSize();
  const cssObject = initiatives[inititative];
  const css = classNames(
    cssObject["positive-text"],
    cssObject["negative-text"],
    `bg-black transition-all duration-150 md:aspect-square w-full p-5 md:p-10 h-[${h}] group relative`
  );
  const [linkState, setLinkState] = useState(cssObject["link-positive"]);

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.7 }}
      whileInView={{ opacity: 1, scale: 1 }}
      {...rest}
      className={css}
      onMouseEnter={() => setLinkState(cssObject["link-negative"])}
      onMouseLeave={() => setLinkState(cssObject["link-positive"])}
    >
      <Link
        href={cssObject.link}
        passHref
        className="text-white group-hover:text-neutral-900"
      >
        <>
          <div className="w-2/3">
            <SvgLogo className="text-white group-hover:text-neutral-900"></SvgLogo>
          </div>

          <Text.Label
            as="p"
            fontWeight="bold"
            size={windowSize.width < breakpoints.lg ? "md" : "xl"}
            className={classNames(cssObject.accent, "mb-2 mt-2 md:mb-5")}
          >
            {cssObject.title}
          </Text.Label>
          <hr className={classNames(cssObject.hr, "bt-2 pb-5")}></hr>
          <Text.Body
            size={windowSize.width < 720 ? "md" : "xl"}
            className="mt-2 md:mt-5"
          >
            {cssObject.description}
          </Text.Body>

          {windowSize.width < breakpoints.md ? (
            <div className="relative bottom-0 mt-3 items-baseline ">
              <Button
                size="lg"
                color="terciary-dark"
                label="Explora"
                className="w-3/4"
              ></Button>
            </div>
          ) : (
            <div className="bottom-0 mt-3 items-baseline pb-4 xl:absolute ">
              <Text.Link
                size={windowSize.width < breakpoints.lg ? "lg" : "xl"}
                theme={linkState}
                label="Explora"
                trailingIcon={<ArrowNarrowRightIcon />}
              ></Text.Link>&nbsp;
            </div>
          )}
        </>
      </Link>
    </motion.div>
  );
}

InitiativeCard.propTypes = {
  inititative: PropTypes.oneOf([
    "ambulante-presenta",
    "ambulante-mas-alla",
    "ediciones",
    "gira-ambulante",
  ]),
};

export function InitiativeCardSimple({ h = "", inititative, ...rest }) {
  const windowSize = useWindowSize();
  const cssObject = initiatives[inititative];
  const css = classNames(
    cssObject["positive-text"],
    cssObject["negative-text"],
    `hover:cursor-pointer bg-black transition-all duration-150 md:aspect-square w-full sm:p-6 md:p-6 lg:p-8 h-[${h}] group relative`
  );
  const [linkState, setLinkState] = useState(cssObject["link-positive"]);
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      whileInView={{ opacity: 1, scale: 1 }}
      {...rest}
      className={css}
      onMouseEnter={() => setLinkState(cssObject["link-negative"])}
      onMouseLeave={() => setLinkState(cssObject["link-positive"])}
    >
      <div className="w-36">
        <Image
          src="/next_assets/img/brand-assets/AmbulanteLogo.png"
          width={100}
          height={15}
          alt="logo"
          layout="responsive"
        />

        {/* <SvgLogo className="text-white group-hover:text-neutral-900"></SvgLogo> */}
      </div>

      <Text.Label
        as="p"
        fontWeight="bold"
        size={windowSize.width < breakpoints.lg ? "md" : "xl"}
        className={classNames(
          cssObject.accent,
          "my-2 sm:text-lg md:mb-2 md:text-sm"
        )}
      >
        {cssObject.secondTitle}
      </Text.Label>
      <Text.Body
        as="p"
        size={windowSize.width < 720 ? "md" : "xl"}
        className="mt-2 leading-5 md:mt-2 md:text-[14px]"
      >
        {cssObject.description}
      </Text.Body>
    </motion.div>
  );
}

InitiativeCardSimple.propTypes = {
  inititative: PropTypes.oneOf([
    "ambulante-presenta",
    "ambulante-mas-alla",
    "ediciones",
    "gira-ambulante",
    "ambulante-presenta",
  ]),
};

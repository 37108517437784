import * as Text from "@/components/text";
import React, { useState } from "react";
import SocialHead from "@/components/SEO/SocialHead";
import Link from "next/link";
import Modal from "@/components/lib/Modal";
import CallToAction from "@/components/catalog/CallToAction";
import dynamic from "next/dynamic";
import qs from "qs";

import HomeSlider from "@/components/content/HomeSlider";
import InitiativeCard from "@/components/banners/InitiativeCard";
import TourBanner from "@/components/banners/TourBanner";
import {
  Featured,
  VerticalCard,
  HorizontalCard,
} from "@/components/publications/Cards";
import CardsGallery from "@/components/publications/CardsGallery";
import { Nav } from "@/components/navigation/Navbar";
import { getStrapiImage } from "@/utils/strapiFunctions";

import useWindowSize from "@/hooks/useWindowSize";
import breakpoints from "@/constants/breakpoinst";
import apiClient from "@/utils/apiClient";

const ExploreMovies = dynamic(
  () => import("@/components/content/ExploreMovies"),
  {
    loading: () => <>Cargando...</>,
  }
);

export default function Home({ movieImages, movieSlides, home }) {
  let [isOpen, setIsOpen] = useState(home?.popup?.active);
  const windowSize = useWindowSize();

  return (
    <div>
      <SocialHead
        title="Ambulante - Gira de documentales"
        description="Gira de documentales"
      />
      {/* <SearchBar display={showSearch} setDisplay={toggleSearch} /> */}
      <div className="relative w-full">
        <div className=" absolute top-0 z-30 m-auto inline-flex  w-full   ">
          <div className="  w-full ">
            <div className="amb-container">
              <Nav className="grow bg-gray-100" theme="dark" />
            </div>
          </div>
        </div>
        <HomeSlider slides={movieSlides} />

        {/* Noticias */}
        <div className="section xl:p-0 ">
          <div className="mb-14 lg:flex">
            <div className="w-2/3">
              <Text.Display as="h3">{home.ahora_ambulante.titulo}</Text.Display>
            </div>
            <div className="mr-[9px] lg:w-1/3">
              <Text.Body size="xl">
                {home.ahora_ambulante.descripcion}
              </Text.Body>
            </div>
          </div>
          {home.tour_banner.active ? (
            <TourBanner
              logo={getStrapiImage(home.tour_banner.tour_logo)}
              title={home.tour_banner.title}
              description={home.tour_banner.description}
              image={getStrapiImage(home.tour_banner.background)}
              countdown={home?.tour_banner?.countdown}
            />
          ) : null}
          {windowSize.width >= breakpoints.lg ? (
            <>
              <div className="flex gap-5  ">
                <div className="-mr-[9px] min-h-[350px] w-2/3 lg:h-[490px] lg:w-1/2">
                  <Featured
                    metadata={{
                      date: new Date(
                        home?.ahora_ambulante?.primary_entries?.data[0].attributes?.publishedAt
                      ),
                      type: home?.ahora_ambulante?.primary_entries?.data[0].attributes?.entry_type,
                      length: "",
                    }}
                    description={
                      home?.ahora_ambulante?.primary_entries?.data[0]
                        ?.attributes?.contenido
                    }
                    title={
                      home?.ahora_ambulante?.primary_entries?.data[0].attributes
                        .titulo
                    }
                    image={
                      home?.ahora_ambulante?.primary_entries?.data[0].attributes
                        .cover_image.data?.attributes?.url
                    }
                    link={`/blog/${home?.ahora_ambulante?.primary_entries?.data[0].attributes.slug}`}
                  />
                </div>
                {home?.ahora_ambulante?.primary_entries?.data[1] ? (
                  <div className="min-h-[350px] w-1/3 pl-[9px] lg:h-[490px] lg:w-1/2 lg:pl-0">
                    <Featured
                      metadata={{
                        date: new Date(
                          home?.ahora_ambulante?.primary_entries?.data[1].attributes?.publishedAt
                        ),
                        type: home?.ahora_ambulante?.primary_entries?.data[1].attributes?.entry_type,
                        length: "",
                      }}
                      description={
                        home?.ahora_ambulante?.primary_entries?.data[1]
                          ?.attributes?.contenido
                      }
                      title={
                        home?.ahora_ambulante?.primary_entries?.data[1]
                          .attributes.titulo
                      }
                      image={
                        home?.ahora_ambulante?.primary_entries?.data[1]
                          .attributes.cover_image.data?.attributes?.url
                      }
                      aspect="square"
                      link={`/blog/${home?.ahora_ambulante?.primary_entries?.data[1].attributes.slug}`}
                    />
                  </div>
                ) : null}
              </div>
            </>
          ) : (
            <>
              <CardsGallery
                slidesPerView={windowSize.width >= breakpoints.md ? 2.5 : 1.5}
                spaceBetween={windowSize.width >= breakpoints.md ? 20 : 15}
                slides={home?.ahora_ambulante?.primary_entries?.data}
              />
            </>
          )}

          <div className="gap-5 pt-12 md:grid  md:grid-cols-2 md:pt-16 lg:grid-cols-4 lg:pt-0">
            {windowSize.width >= breakpoints.lg ? (
              <>
                {home?.ahora_ambulante?.secondary_entries?.data
                  .slice(0, 4)
                  .map((post) => (
                    <VerticalCard
                      key={post.id}
                      metadata={{
                        date: new Date(post.attributes.publishedAt),
                        type: post.attributes.entry_type,
                        length: "",
                      }}
                      description={post.attributes.contenido}
                      title={post.attributes.titulo}
                      image={
                        post.attributes.cover_image.data?.attributes?.formats
                          ?.medium?.url
                      }
                      link={`/blog/${post.attributes.slug}`}
                      orientation="landscape"
                    />
                  ))}
              </>
            ) : (
              <>
                {home?.ahora_ambulante?.secondary_entries?.data
                  .slice(0, 4)
                  .map((post) => (
                    <HorizontalCard
                      key={post.id}
                      metadata={{
                        date: new Date(post.attributes.publishedAt),
                        type: post.attributes.entry_type,
                        length: "",
                      }}
                      description={post.attributes.contenido}
                      title={post.attributes.titulo}
                      image={
                        post.attributes.cover_image.data?.attributes?.formats
                          ?.medium?.url
                      }
                      link={`/blog/${post.attributes.slug}`}
                    />
                  ))}
              </>
            )}
          </div>
        </div>
        <hr className="bt-2 border-neutral-1000"></hr>
        <div className="section xl:p-0">
          <div className="mb-14 lg:flex">
            <div className="w-2/3">
              <Text.Display as="h3">Nuestras iniciativas</Text.Display>
            </div>
            <div className="mr-[9px] lg:w-1/3">
              <Text.Body size="xl">
                Nuestra misión es movilizar espacios de encuentro y acción
                colectiva para construir otros mundos a través del documental.
                Conoce cómo lo hacemos.
              </Text.Body>
            </div>
          </div>

          <div className="gap-5 space-y-5 md:flex md:space-y-0">
            <InitiativeCard className="w-1/4" inititative="gira-ambulante" />
            <InitiativeCard
              className="w-1/4"
              inititative="ambulante-presenta"
            />
            <InitiativeCard
              className="w-1/4"
              inititative="ambulante-mas-alla"
            />

            <InitiativeCard className="w-1/4" inititative="ediciones" />
          </div>
        </div>
        <hr className="bt-2 border-neutral-1000"></hr>
        <div className="section">
          <ExploreMovies
            title="Conoce las películas que han pasado por Ambulante"
            subtitle="Descrubre por temáticas, años, países y más"
            button_label="Explora el catálogo"
            images={movieImages}
            button_link="/catalogo"
          />
        </div>




      </div>

      {/* POPUP */}
      {home.popup.active ? (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
          <div className="flex flex-col items-center justify-center">
            <Link href={home.popup.url} target="_blank">
              <img className="" src={getStrapiImage(home.popup.image)} alt="popup" />
            </Link>
          </div>
        </Modal>
      ) : null}


    </div>
  );
}

export async function getServerSideProps() {
  try {
    const query = qs.stringify(
      {
        populate: {
          slides: {
            populate: ["background_image"],
          },
          ahora_ambulante: {
            populate: {
              primary_entries: {
                populate: "*",
                publicationState: "live",
              },
              secondary_entries: {
                populate: "*",
                publicationState: "live",
              },
            },
          },
          tour_banner: {
            populate: {
              tour_logo: {
                populate: "*",
              },
              background: {
                populate: "*",
              },
            },
          },
          popup: {
            populate: "*",
          },
          mosaico: {
            populate: {
              peliculas: {
                populate: {
                  film_cover_image: "*",
                },
              },
            },
          },
        },
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    );

    const home = await apiClient.get(`/api/home?${query}`);

    const mosaicImages =
      home?.data?.data?.attributes?.mosaico?.peliculas?.data.map((m) =>
        getStrapiImage(m?.attributes?.film_cover_image)
      );

    const slides = home?.data?.data?.attributes?.slides;

    // generate movieslides from slides
    const movieSlides = slides.map((s) => {
      return {
        big: {
          head: s.header_text,
          title: s.title,
          subtitle: s.subtitle,
          description: s.description,
          image: getStrapiImage(s?.background_image) || "",
          link: s.link,
          link_label: s.link_label,
        },
        small: {
          head: s.header_text,
          title: s.title,
          subtitle: s.subtitle,
          description: s.description,
          thumbImage: getStrapiImage(s?.background_image) || "",
        },
      };
    });

    const q = qs.stringify({
      populate: "*",
    });

    return {
      props: {
        home: home?.data.data.attributes,
        movieImages: mosaicImages,
        movieSlides,
      },
    };
  } catch (err) {
    return {
      notFound: true,
    };
  }
}
